import AddAPhoto from '@material-ui/icons/AddAPhoto'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import React from 'react'
import colors from '../../config/colors'
import ModifyImagePopin from '../../modules/advice/components/ModifyImagePopin'

class FieldFile extends React.Component {

	constructor(props) {
		super(props)
		this.onChange = this.onChange.bind(this)
		this.state = {
			files: this.props.documents,
			ticket: this.props.ticket
		}
	}

	deleteFile = (file, idDeleted, setIdDeleted) => {
		if (window.confirm('Etes-vous sur de vouloir supprimer cette photo ?')) {
			this.setState({
				files: this.state.files.filter(item => file !== item)
			})
			setIdDeleted([...idDeleted, file.id])
		}
	}

	onSuccessBase64 = (newBase64, base64List, setBase64List, idDeleted, setIdDeleted) => {
		if (this.state.currentId !== undefined) {
			setBase64List({
				...base64List,
				[this.state.currentId]: newBase64
			})
		}
	}

	openPopinModification = (url, file) => {
		this.setState({ popinUrl: url })
		this.setState({ currentId: file.id })
	}

	onChange(e) {
		const { input: { onChange } } = this.props

		onChange(e.target.files)

		if (this.state.files && this.state.files.length > 0) {
			this.setState({
				files: [...this.state.files, ...e.target.files]
			})
		} else {
			this.setState({
				files: [...e.target.files]
			})
		}
	}


	render() {
		const { input: { value }, label, required, meta, ticket, documents, idDeleted, setIdDeleted, base64List, setBase64List } = this.props  //whatever props you send to the component from redux-form Field

		return (
			<div>
				<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
					<span style={{ color: colors.primary, fontWeight: 700, fontSize: 12, marginRight: 20 }}>Photos associées</span>
					<label htmlFor="file">
						<AddAPhoto style={{ color: colors.primary }} />
					</label>
				</div>
				<div>
					<input
						type='file'
						id='file'
						multiple
						style={{ display: 'none' }}
						accept='.jpg, .png, .jpeg'
						onChange={this.onChange}
					/>
				</div>
				{ticket && this.state.files && Array.from(this.state.files).map((file, index) =>
					<div key={index} style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
						{!base64List[file.id] &&
						<img src={file.filename ? `/api/file/${file.id}?ticket=${ticket}` : URL.createObjectURL(file)} width={300} height={300}
						     style={{ objectFit: 'contain' }} />}
						{file.id && base64List[file.id] && <img src={base64List[file.id]} width={300} height={300} />}
						<div onClick={() => {
							this.openPopinModification(file.filename ? `/api/file/${file.id}?ticket=${ticket}` : URL.createObjectURL(file), file)
						}}>
							{file.id && <Edit style={{ color: colors.primary }} />}
						</div>
						<div onClick={() => this.deleteFile(file, idDeleted, setIdDeleted)}>
							<Delete style={{ color: colors.primary }} />
						</div>
					</div>
				)}
				<div>
				</div>
				{this.state.popinUrl && <ModifyImagePopin onClose={() => this.setState({ popinUrl: undefined })} onSuccess={(newBase64, file) => {
					this.onSuccessBase64(newBase64, base64List, setBase64List, idDeleted, setIdDeleted)
				}} url={this.state.popinUrl} open={true} />}
			</div>
		)
	}
}

export default FieldFile