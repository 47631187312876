import { Typography, withStyles } from '@material-ui/core'
import { getStyles, injectToolbarData, StaticDataTable } from 'isotope-client'
import * as moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import FloatingButton from '../../components/form/FloatingButton'
import Divider from '../../components/layout/Divider'
import ErrorPopin from '../../components/layout/ErrorPopin'
import Loader from '../../components/layout/Loader'
import { ADVICE_STATE } from '../../config/constants'
import styles from '../../styles'
import { getZones } from '../zone/services/zoneActions'
import * as zoneSelectors from '../zone/services/zoneSelectors'
import NewTipPopin from './components/NewAdvicePopin'
import PublishAdvicePopin from './components/PublishAdvicePopin'
import SelectZonePopin from './components/SelectZonePopin'
import SuppressionConseilPopin from './components/DeleteAdvicePopin'
import { fetchAdvices } from './services/adviceActions'
import * as adviceSelectors from './services/adviceSelectors'

const style = () => getStyles({
	...styles
})

const AdvicePage = ({ intl, classes, advices, fetchTips, getZones, districts, centers, ...otherProps }) => {
	const [loading, setLoading] = React.useState(false)
	const [openAddPopup, setOpenAddPopup] = React.useState(false)
	const [openDeletePopup, setOpenDeletePopup] = React.useState(false)
	const [openPublishPopup, setOpenPublishPopup] = React.useState(false)
	const [openSelectZonePopup, setOpenSelectZonePopup] = React.useState(false)
	const [openErrorPopup, setOpenErrorPopup] = React.useState(false)
	const [error, setError] = React.useState(undefined)
	const [currentAdvice, setCurrentAdvice] = React.useState(undefined)
	const match = otherProps.match

	React.useEffect(() => {
		if (match.params.brandId) {
			setLoading(true)
			getZones()
				.then(() => fetchTips(match.params.brandId))
				.then(() => {
					setLoading(false)
				})
				.catch(() => {
					setLoading(false)
				})
		}
	}, [])

	const onClosePopup = (action) => {
		setCurrentAdvice(undefined)
		action()
	}

	const actionModifier = (advice) => {
		setCurrentAdvice(advice)
		setOpenAddPopup(true)
	}

	const actionSupprimer = (advice) => {
		setCurrentAdvice(advice)
		setOpenDeletePopup(true)
	}

	const actionPublier = (advice) => {
		setCurrentAdvice(advice)
		setOpenPublishPopup(true)
	}

	const numberOfTipsWainting = () => {
		const nbTips = advices && advices ? advices.reduce((acc, val) => acc + (val.state === ADVICE_STATE.MODERATE ? 1 : 0), 0) : 0
		return nbTips
	}

	return (
		<>
			<Divider />
			<div className={classes.content}>
				<Typography
					variant="h5">{intl.formatMessage({ id: numberOfTipsWainting() === 0 ? 'advice.subTitleTipNone' : (numberOfTipsWainting() > 1 ? 'advice.subTitleTips' : 'advice.subTitleTip') }, { number: numberOfTipsWainting() })}</Typography>
				{loading ?
					<Loader /> :
					<>
						<div className={classes.tableContent}>
							<StaticDataTable
								className={classes.dataTable}
								data={(advices || [])
									.sort((a, b) => {
										const alphaSort = a.model && b.model && a.model.localeCompare(b.model)
										const dateSort = a.modificationDate && a.modificationDate.localeCompare(b.modificationDate)
										return alphaSort || dateSort
									})
									.filter(advice => advice.state !== ADVICE_STATE.DELETED)}
								defaultPageSize={50}
								headers={[
									{
										key: 'model',
										name: <div className={classes.key}><FormattedMessage id='advice.keys.modele' /></div>,
										render: row => row.model,
										type: 'string',
										sortable: true
									},
									{
										key: 'description',
										name: <div className={classes.key}><FormattedMessage id='advice.keys.advice' /></div>,
										render: row => row.description
									},
									{
										key: 'likes',
										name: <div className={classes.key}><FormattedMessage id='advice.keys.likes' /></div>,
										render: row => row.likes.length ? row.likes.length : 0,
										type: 'string',
										sortable: true
									},
									{
										key: 'photos',
										name: <div className={classes.key}><FormattedMessage id='advice.keys.photos' /></div>,
										render: row => row.documents.length ? row.documents.length : 0,
										type: 'string',
										sortable: true
									},
									{
										key: 'modificationDate',
										name: <div className={classes.key}><FormattedMessage id='advice.keys.dateModif' /></div>,
										render: row => moment(row.modificationDate).format('MM/DD/YYYY'),
										type: 'date',
										sortable: true
									},
									{
										key: 'state',
										name: <div className={classes.key}><FormattedMessage id='advice.keys.state' /></div>,
										render: row => row.state === ADVICE_STATE.MODERATE ?
											(
												<div className={classes.pastille}>
											<span className={classes.pastilleText}>
												<FormattedMessage id={`advice.state.${row.state}`} />
											</span>
												</div>
											) :
											<FormattedMessage id={`advice.state.${row.state}`} />,
										sortable: true
									}
								]}
								makeActions={row => [
									{ label: <FormattedMessage id='general.btn.modifier' />, action: () => actionModifier(row) },
									{ label: <FormattedMessage id='general.btn.delete' />, action: () => actionSupprimer(row) },
									row.state === ADVICE_STATE.MODERATE ?
										{ label: <FormattedMessage id='advice.actions.publier' />, action: () => actionPublier(row) } :
										row.state === ADVICE_STATE.PUBLISHED && {
											label: <FormattedMessage id='advice.actions.depublier' />, action: () => actionPublier(row)
										}

								]}
								showActions
							/>
						</div>
						<FloatingButton onClick={() => setOpenAddPopup(true)} />
						{openAddPopup &&
							<NewTipPopin
								open={openAddPopup}
								onClose={() => onClosePopup(() => setOpenAddPopup(false))}
								onError={(e) =>
									onClosePopup(() => {
										setError(e)
										setOpenErrorPopup(true)
										setOpenAddPopup(false)
									})
								}
								onOpenSelectZone={() => setOpenSelectZonePopup(true)}
								advice={currentAdvice}
								brandId={match.params.brandId}
								centers={centers}
							/>
						}
						<SuppressionConseilPopin
							open={openDeletePopup}
							onClose={() => onClosePopup(() => setOpenDeletePopup(false))}
							onError={(e) =>
								onClosePopup(() => {
									setError(e)
									setOpenDeletePopup(false)
									setOpenErrorPopup(true)
								})
							}
							advice={currentAdvice}
						/>
						<PublishAdvicePopin
							open={openPublishPopup}
							onClose={() => onClosePopup(() => setOpenPublishPopup(false))}
							onError={(e) =>
								onClosePopup(() => {
									setError(e)
									setOpenPublishPopup(false)
									setOpenErrorPopup(true)
								})
							}
							advice={currentAdvice}
						/>
						<SelectZonePopin
							open={openSelectZonePopup}
							onClose={() => setOpenSelectZonePopup(false)}
							districts={districts}
							centers={centers}
						/>
						<ErrorPopin
							open={openErrorPopup}
							onClose={() => setOpenErrorPopup(false)}
							errorTitle={error && error.title}
							errorMessage={error && error.message}
						/>
					</>
				}
			</div>
		</>
	)
}

AdvicePage.propTypes = {
	intl: PropTypes.object,
	advices: PropTypes.array,
	districts: PropTypes.array,
	centers: PropTypes.array,
	fetchTips: PropTypes.func,
	getZones: PropTypes.func
}

const mapStateToProps = (state, { match: { params: { brandId } } }) => ({
	advices: adviceSelectors.getAdvices(brandId, state),
	districts: zoneSelectors.getDistricts(state),
	centers: zoneSelectors.getCenters(state)
})

const actions = {
	fetchTips: fetchAdvices,
	getZones
}

export default compose(
	injectIntl,
	connect(mapStateToProps, actions),
	withStyles(style),
	injectToolbarData(({ ...otherProps }) => ({
		title: <FormattedMessage id="advice.title" />,
		subheader: {
			separator: () => '/',
			filArianeEntries: [{
				text: otherProps.match.params.brandName && otherProps.match.params.brandName
			}]
		}
	}))
)(AdvicePage)
