import { withStyles } from '@material-ui/core'
import { FormInput, FormSwitch, getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reset, Field, formValueSelector, isPristine, reduxForm } from 'redux-form'
import Button from '../../../components/form/Button'
import FieldFile from '../../../components/form/FieldFile'
import Popin from '../../../components/layout/Popin'
import { CREER_ADVICE_FORM, MAX_CHAR, MODELE_ALL } from '../../../config/constants'
import { getDocument, getTicket, newAdvice, updateAdvice } from '../services/adviceActions'

const style = () => getStyles({
	switch: {
		marginBottom: 20
	}
})

const validate = (values) => {
	const errors = {}

	if (!values.allModel && !values.model) {
		errors.model = { id: 'general.erreur.obligatoire' }
	}
	if (!values.description) {
		errors.description = { id: 'general.erreur.obligatoire' }
	}
	if (!values.centerId) {
		errors.centerId = { id: 'general.erreur.obligatoire' }
	}
	if (!values.authorLastname) {
		errors.authorLastname = { id: 'general.erreur.obligatoire' }
	}
	if (!values.authorFirstname) {
		errors.authorFirstname = { id: 'general.erreur.obligatoire' }
	}

	return errors
}

const NewTipPopin = ({ classes, open, submitting, handleSubmit, intl, onClose, onError, onOpenSelectZone, clearForm, reset, getTicket, getDocument, newAdvice, updateAdvice, advice, allModel, change }) => {

	const [ticket, setTicket] = React.useState(null)

	const [idDeleted, setIdDeleted] = React.useState([])
	const [base64List, setBase64List] = React.useState({})

	React.useEffect(()=>{
		getTicket().then((response)=> {
			setTicket(response.ticket.ticket)
			console.log(response.ticket.ticket)
		})
	},[])

	const submitCreation = (values) => {

		if (advice) {
			updateAdvice(values, idDeleted, base64List)
				.then(() => closePopup())
				.catch((e) => onError({
						title: intl.formatMessage({ id: 'error.technique.title' }),
						message: intl.formatMessage({ id: 'error.technique.message' })
					}
				))
		} else {
			newAdvice(values)
				.then(() => closePopup())
				.catch((e) => onError({
						title: intl.formatMessage({ id: 'error.technique.title' }),
						message: intl.formatMessage({ id: 'error.technique.message' })
					}
				))
		}
	}

	const closePopup = () => {
		clearForm()
		onClose()
	}

	const actions = [
		<Button
			key="close"
			color="primary"
			onClick={closePopup}
		>
			<FormattedMessage id="general.btn.annuler" />
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			loading={submitting}
			onClick={handleSubmit(submitCreation)}
		>
			{advice ? <FormattedMessage id='general.btn.modifier' /> : <FormattedMessage id='general.btn.creer' />}
		</Button>
	]

	return <Popin
		open={open}
		actions={actions}
		title={advice ? intl.formatMessage({ id: 'advice.modification.title' }) : intl.formatMessage({ id: 'advice.creation.title' })}
		onClose={closePopup}
		content={
			<form>
				<div className={classes.switch}>
					<Field
						name="allModel"
						label={intl.formatMessage({ id: 'advice.creation.allModel' })}
						component={FormSwitch}
						color='primary'
						onChange={(ev, val) => change('model', val ? MODELE_ALL : '')}
					/>
				</div>
				<Field
					name="model"
					label={allModel ? intl.formatMessage({ id: 'advice.creation.modele' }) : intl.formatMessage({ id: 'advice.creation.modeleMandatory' })}
					component={FormInput}
					inputProps={{ maxLength: MAX_CHAR.advice.model }}
					disabled={allModel ? true : false}
				/>
				<Field
					name="description"
					label={intl.formatMessage({ id: 'advice.creation.description' })}
					component={FormInput}
					multiline
				/>
				<Field
					name="center"
					label={intl.formatMessage({ id: 'advice.creation.center' })}
					component={FormInput}
					onClick={onOpenSelectZone}
					readOnly
				/>
				<Field
					name="authorLastname"
					label={intl.formatMessage({ id: 'advice.creation.auteur.lastname' })}
					component={FormInput}
					inputProps={{ maxLength: MAX_CHAR.advice.author.lastname }}
				/>
				<Field
					name="authorFirstname"
					label={intl.formatMessage({ id: 'advice.creation.auteur.firstname' })}
					component={FormInput}
					inputProps={{ maxLength: MAX_CHAR.advice.author.firstname }}
				/>
				<Field
					name="documents"
					label={intl.formatMessage({ id: 'advice.creation.documents' })}
					component={FieldFile}
					ticket={ticket}
					setIdDeleted={setIdDeleted}
					setBase64List={setBase64List}
					idDeleted={idDeleted}
					base64List={base64List}
					documents={advice && advice.documents}
				/>
			</form>
		}
	/>
}

NewTipPopin.propTypes = {
	open: PropTypes.bool,
	submitting: PropTypes.bool,
	intl: PropTypes.object,
	handleSubmit: PropTypes.func,
	clearForm: PropTypes.func,
	onClose: PropTypes.func,
	onError: PropTypes.func,
	onOpenSelectZone: PropTypes.func,
	newAdvice: PropTypes.func,
	updateAdvice: PropTypes.func,
	advice: PropTypes.object,
	allModel: PropTypes.bool,
	brandId: PropTypes.string,
	centers: PropTypes.array,
}

const formSelector = formValueSelector(CREER_ADVICE_FORM)

const mapStateToProps = (state, { advice, brandId, centers }) => ({
	initialValues: {
		...advice,
		allModel: advice && advice.model && advice.model === MODELE_ALL ? true : false,
		center: centers && advice && centers.find(center => center.id === advice.centerId) && centers.find(center => center.id === advice.centerId).name,
		districtId: centers && advice && centers.find(center => center.id === advice.centerId) && centers.find(center => center.id === advice.centerId).districtId,
		brandId
	},
	allModel: formSelector(state, 'allModel'),
	submitting: isPristine(CREER_ADVICE_FORM)(state)
})

const actions = {
	clearForm: () => dispatch => dispatch(reset(CREER_ADVICE_FORM)),
	newAdvice: newAdvice,
	updateAdvice: updateAdvice,
	getTicket: getTicket,
	getDocument: getDocument
}

export default compose(
	withStyles(style),
	connect(mapStateToProps, actions),
	injectIntl,
	reduxForm({
		form: CREER_ADVICE_FORM,
		validate,
		enableReinitialize: true
	})
)(NewTipPopin)
