import {
	isAuthorized as isAuthorizedApi,
	Page,
	refresh as refreshApi,
	refreshToken as refreshTokenAction,
	storeLocalUser as storeLocalUserApi
} from 'isotope-client'
import { loadLangues } from 'isotope-client/modules/langue/services/langueActions'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import AssistPage from '../modules/assistance/AssistPage'
import AdvicePage from '../modules/advice/AdvicePage'
import BrandPage from '../modules/brand/BrandPage'
import UserPage from '../modules/user/UserPage'
import { ProfilePage } from 'isotope-client'

class App extends Component {
	constructor(props) {
		super(props)
		const { user, location, logout } = this.props
		if (user.authenticated) {
			// Si le user est bien authentifié, je vérifie les droits
			isAuthorizedApi(location.pathname)
				.then(retour => {
					if (!retour) logout()
				})
		}

		this.refresh()
		this.refreshInterval = setInterval(() => {
			this.refresh()
		}, 1700 * 1000)
	}

	refresh() {
		const { user, refreshAction, loadLanguesAction } = this.props
		if (user && user.token) {
			const token = localStorage.getItem('token')
			refreshApi(token).then((json) => {
				if (json) {
					refreshAction(json.token)
					storeLocalUserApi(json)
				}
			})
		}
		loadLanguesAction()
	}

	componentWillUnmount() {
		if (this.refreshInterval) {
			clearInterval(this.refreshInterval)
		}
	}

	render() {
		const { location, user } = this.props
		if (!user.authenticated) {
			return (<Redirect to="/login" />)
		} else {
			return (
				<Page location={location}>
					<Switch>
						<Route exact path='/' component={BrandPage} />
						<Route exact path='/users' component={UserPage} />
						<Route exact path='/advices' component={BrandPage} />
						<Route exact path='/advices/:brandId/:brandName' component={AdvicePage} />
						<Route exact path='/assist' component={AssistPage} />
						<Route exact path='/me' component={ProfilePage} />
					</Switch>
				</Page>
			)
		}
	}

}

App.propTypes = {
	location: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired,
	refreshAction: PropTypes.func.isRequired,
	user: PropTypes.object,
	loadLanguesAction: PropTypes.func.isRequired
}

// TODO mettre un selector ici
const mapStateToProps = (state) => ({
	user: state.user
})

const actions = {
	refreshAction: refreshTokenAction,
	logout: () => console.log('logout'),
	loadLanguesAction: loadLangues
}

export default connect(mapStateToProps, actions)(App)
