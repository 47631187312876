import colors from './config/colors'

const styles = {
	content: {
		paddingLeft: 45,
		paddingRight: 45,
		paddingTop: 20,
		paddingBottom: 20,
	},
	key: {
		color: colors.primary,
		fontSize: 13,
		fontWeight: 400
	},
	tableContent: {
		marginBottom: 35
	},
	pastille: {
		display: 'inline-flex',
		backgroundColor: colors.tertiary,
		borderRadius: 20,
		paddingLeft: 21,
		paddingRight: 21,
		paddingTop: 8,
		paddingBottom: 8,
		alignItems: 'center',
		justifyContent: 'center'
	},
	pastilleText: {
		fontcolor: colors.text,
		fontWeight: 600
	}
}

export default styles
