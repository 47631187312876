import { fetchFactory } from 'isotope-client'

export const fetchBrands = () => fetchFactory(`/brands`)

export const newBrand = (brand) => fetchFactory('/brands', {
	method: 'POST',
	body: JSON.stringify(brand)
})

export const updateBrand = (brand) => fetchFactory('/brands', {
	method: 'PUT',
	body: JSON.stringify(brand)
})

export const deleteBrand = (id) => fetchFactory(`/brands/${id}`, {
	method: 'DELETE'
})
