import { FormInput } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { destroy, Field, isPristine, reduxForm } from 'redux-form'
import Button from '../../../components/form/Button'
import Popin from '../../../components/layout/Popin'
import { newAssist, updateAssist } from '../services/assistActions'

const NEW_ASSIST_FORM = 'NEW_ASSIST_FORM'

const validate = (values) => {
	const errors = {}

	if (!values.code) {
		errors.code = { id: 'general.erreur.obligatoire' }
	}

	if (!values.label) {
		errors.label = { id: 'general.erreur.obligatoire' }
	}

	return errors
}

const NewAssistPopin = ({ open, submitting, handleSubmit, intl, onClose, onError, clearForm, newAssist, updateAssist, assists, assist, assistType }) => {
	const submitCreation = (values) => {

		// On check si on a un num assist existant désactivé
		const existingAssistance = assists.find(assist =>
			assist.code.toLowerCase() === values.code.toLowerCase() &&
			assist.label.toLowerCase() === values.label.toLowerCase() &&
			assist.disabled
		)

		const newAssistance = {
			...values,
			// On remet l'id de l'assist qu'on modifie ou  l'id de l'assist qu'on a déjà dans le store mais avec un état a disabled
			id: (assist && assist.id) || (existingAssistance && existingAssistance.id),
			idValueList: assistType.id
		}

		if (assist) {
			// Modification
			updateAssist(newAssistance)
				.then(() => closePopup())
				.catch((e) => onError({
						title: intl.formatMessage({ id: 'error.technique.title' }),
						message: intl.formatMessage({ id: 'error.technique.message' })
					}
				))
		} else {
			// Creation
			newAssist(newAssistance)
				.then(() => closePopup())
				.catch((e) => onError({
						title: intl.formatMessage({ id: 'error.technique.title' }),
						message: intl.formatMessage({ id: 'error.technique.message' })
					}
				))
		}
	}

	const closePopup = () => {
		clearForm()
		onClose()
	}

	const actions = [
		<Button
			key="close"
			color="primary"
			onClick={closePopup}
		>
			<FormattedMessage id="general.btn.annuler" />
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			loading={submitting}
			onClick={handleSubmit(submitCreation)}
		>
			{assist ? <FormattedMessage id='general.btn.modifier' /> : <FormattedMessage id='general.btn.creer' />}
		</Button>
	]

	return <Popin
		open={open}
		actions={actions}
		title={intl.formatMessage({ id: 'assist.creation.title' })}
		onClose={closePopup}
		content={
			<form>
				<Field
					name="code"
					label={intl.formatMessage({ id: 'assist.creation.district' })}
					component={FormInput}
				/>
				<Field
					name="label"
					label={intl.formatMessage({ id: 'assist.creation.numero' })}
					component={FormInput}
				/>
			</form>
		}
	/>
}

NewAssistPopin.propTypes = {
	open: PropTypes.bool,
	submitting: PropTypes.bool,
	intl: PropTypes.object,
	handleSubmit: PropTypes.func,
	clearForm: PropTypes.func,
	onClose: PropTypes.func,
	onError: PropTypes.func,
	newAssist: PropTypes.func,
	updateAssist: PropTypes.func,
	assistType: PropTypes.object,
	assist: PropTypes.object,
	assists: PropTypes.array

}

const mapStateToProps = (state, { assist }) => ({
	initialValues: {
		...assist
	},
	submitting: isPristine(NEW_ASSIST_FORM)(state)
})

const actions = {
	clearForm: () => dispatch => dispatch(destroy(NEW_ASSIST_FORM)),
	newAssist: newAssist,
	updateAssist: updateAssist
}

export default compose(
	connect(mapStateToProps, actions),
	injectIntl,
	reduxForm({
		form: NEW_ASSIST_FORM,
		validate,
		enableReinitialize: true
	})
)(NewAssistPopin)
