import * as actions from './adviceActions'

const initialState = {
	advices: {},
	ticket: null
}

export const advice = (state = initialState, action) => {
	switch (action.type) {
		case actions.GET_ADVICES_BY_BRAND_SUCCESS:
			return {
				...state,
				advices: {
					[action.brandId]: action.advices
				}
			}
		case actions.GET_TICKET_SUCCESS:
			return {
				...state,
				ticket: action.ticket
			}
		case actions.GET_TICKET_ERROR:
			return state
		case actions.GET_DOCUMENT_SUCCESS:
			return state
		case actions.GET_DOCUMENT_ERROR:
			return state
		case actions.POST_ADVICE_SUCCESS:
			return {
				...state,
				advices: {
					...state.advices,
					[action.advice.brandId]: [...state.advices[action.advice.brandId], action.advice]
				}
			}
		case actions.PUT_ADVICE_SUCCESS:
		case actions.PUT_ADVICE_STATUS_SUCCESS:
			return {
				...state,
				advices: {
					...state.advices,
					[action.advice.brandId]: [...(state.advices[action.advice.brandId] || []).filter(advice => advice.id !== action.advice.id), action.advice]
				}
			}
		case actions.GET_ADVICES_BY_BRAND_ERROR:
			return state
		default:
			return state
	}
}
