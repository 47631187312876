import * as api from './zoneApi'

export const GET_ZONES_REQUEST = 'GET_ZONES_REQUEST'
export const GET_ZONES_SUCCESS = 'GET_ZONES_SUCCESS'
export const GET_ZONES_ERROR = 'GET_ZONES_ERROR'

export const getZones = () => dispatch => {
	dispatch({ type: GET_ZONES_REQUEST })

	return api.getZones()
		.then((zones) => dispatch({ type: GET_ZONES_SUCCESS, zones }))
		.catch((e) => {
			dispatch({ type: GET_ZONES_ERROR })
			throw e
		})
}
