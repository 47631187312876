import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import React from 'react'

const styles = () => getStyles({
	buttonGroup: {
		marginTop: 20
	},
	button: {
		marginRight: 7
	}
})

const actions = ({ children, classes }) => {
	if (!children) {
		return null
	}

	if (children.length === undefined) {
		children = [children]
	}
	return <Grid
		container
		justify="flex-end"
		className={classes.buttonGroup}
	>
		{children.map((button, index) =>
			<Grid className={index + 1 < children.length ? classes.button : null} key={index} item>{button}</Grid>
		)}
	</Grid>
}

export default withStyles(styles)(actions)
