import * as api from './brandApi'

export const GET_BRANDS_REQUEST = 'GET_BRANDS_REQUEST'
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS'
export const GET_BRANDS_ERROR = 'GET_BRANDS_ERROR'

export const fetchBrands = () => dispatch => {
	dispatch({ type: GET_BRANDS_REQUEST })

	return api.fetchBrands()
		.then((brands) => dispatch({ type: GET_BRANDS_SUCCESS, brands }))
		.catch((e) => {
			dispatch({ type: GET_BRANDS_ERROR })
			throw e
		})
}

export const POST_BRAND_REQUEST = 'POST_BRAND_REQUEST'
export const POST_BRAND_SUCCESS = 'POST_BRAND_SUCCESS'
export const POST_BRAND_ERROR = 'POST_BRAND_ERROR'

export const newBrand = (form) => dispatch => {
	dispatch({ type: POST_BRAND_REQUEST })

	return api.newBrand(form)
		.then((brand) => dispatch({
				type: POST_BRAND_SUCCESS,
				brand: brand
			})
		)
		.catch((e) => {
			dispatch({ type: POST_BRAND_ERROR })
			throw e
		})
}

export const PUT_BRAND_REQUEST = 'PUT_BRAND_REQUEST'
export const PUT_BRAND_SUCCESS = 'PUT_BRAND_SUCCESS'
export const PUT_BRAND_ERROR = 'PUT_BRAND_ERROR'

export const updateBrand = (form) => dispatch => {
	dispatch({ type: PUT_BRAND_REQUEST })

	return api.updateBrand(form)
		.then((brand) => dispatch({
				type: PUT_BRAND_SUCCESS,
				brand: brand
			})
		)
		.catch((e) => {
			dispatch({ type: PUT_BRAND_ERROR })
			throw e
		})
}

export const DELETE_BRAND_REQUEST = 'DELETE_BRAND_REQUEST'
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS'
export const DELETE_BRAND_ERROR = 'DELETE_BRAND_ERROR'

export const deleteBrand = (id) => dispatch => {
	dispatch({ type: DELETE_BRAND_REQUEST })

	return api.deleteBrand(id)
		.then((brand) => dispatch({ type: DELETE_BRAND_SUCCESS, brand: brand }))
		.catch((e) => {
			dispatch({ type: DELETE_BRAND_ERROR })
			throw e
		})
}

