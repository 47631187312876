const colors = {
	primary: '#202DEB',
	secondary: '#00F599',
	tertiary: '#FFDD00',
	border: '#0000001F',
	error: '#F44336',
	background: '#FFFFFF',
	text: '#2E2E2E',
	dialogBackground: '#F3F3F3'
}

export default colors
