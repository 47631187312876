import React from 'react'
import MuiDivider from '@material-ui/core/Divider'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import colors from '../../config/colors'

const styles = () => getStyles({
	divider: {
		color: colors.border,
		marginBottom: 15
	}
})

const Divider = ({ classes }) => <MuiDivider className={classes.divider} />

export default withStyles(styles)(Divider)
