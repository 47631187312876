import { fetchFactory, postMultipart } from 'isotope-client'
import { serializeParts } from 'isotope-client/utils/serialize'
import { fetchCustom } from './fetchCustom'

export const fetchAdvices = (brandId) => fetchFactory(`/advices/${brandId}`)

export const newAdvice = ({ documents, ...advice }) => {
	const parts = [{
		key: 'conseilForm',
		value: advice
	}, {
		key: 'documents',
		value: documents
	}]

	return postMultipart('/advices', {
		method: 'POST',
		body: serializeParts(parts)
	})
}

export const updateAdvice = ({ documents, ...advice }, idDeleted, base64List) => {

	const parts = [{
		key: 'conseilForm',
		value: advice
	}, {
		key: 'documents',
		value: documents
	},
		{
			key: 'idDeleted',
			value: idDeleted
		},
		{
			key: 'base64List',
			value: base64List
		}]

	return postMultipart('/advices', {
		method: 'PUT',
		body: serializeParts(parts)
	})
}


export const getTicket = () => fetchCustom('/api/ticket', {
	method: 'GET'
})

export const getDocument = (idAdvice, filename, ticket) => fetchCustom(`/api/file/${idAdvice}/${filename}?ticket=${ticket}`, {
	method: 'GET'
})

export const updateAdviceStatus = (advice) => fetchFactory('/advices/status', {
	method: 'PUT',
	body: JSON.stringify(advice)
})
