import * as api from './assistApi'

export const GET_ASSIST_TYPE_REQUEST = 'GET_ASSIST_TYPE_REQUEST'
export const GET_ASSIST_TYPE_SUCCESS = 'GET_ASSIST_TYPE_SUCCESS'
export const GET_ASSIST_TYPE_ERROR = 'GET_ASSIST_TYPE_ERROR'

export const fetchAssistsType = () => dispatch => {
	dispatch({ type: GET_ASSIST_TYPE_REQUEST })

	return api.fetchAssistType()
		.then((assistType) => {
			dispatch({ type: GET_ASSIST_TYPE_SUCCESS, assistType })
			return assistType
		})
		.catch((e) => {
			dispatch({ type: GET_ASSIST_TYPE_ERROR })
			throw e
		})
}

export const GET_ASSIST_REQUEST = 'GET_ASSIST_REQUEST'
export const GET_ASSIST_SUCCESS = 'GET_ASSIST_SUCCESS'
export const GET_ASSIST_ERROR = 'GET_ASSIST_ERROR'

export const fetchAssistsList = (typeId) => dispatch => {
	dispatch({ type: GET_ASSIST_REQUEST })

	return api.fetchAssistsList(typeId)
		.then((assists) => {
			dispatch({ type: GET_ASSIST_SUCCESS, typeId, assists })
			return assists
		})
		.catch((e) => {
			dispatch({ type: GET_ASSIST_ERROR })
			throw e
		})
}

export const POST_ASSIST_REQUEST = 'POST_ASSIST_REQUEST'
export const POST_ASSIST_SUCCESS = 'POST_ASSIST_SUCCESS'
export const POST_ASSIST_ERROR = 'POST_ASSIST_ERROR'

export const newAssist = (form) => dispatch => {
	dispatch({ type: POST_ASSIST_REQUEST })

	return api.newAssist(form)
		.then((assists) => dispatch({
				type: POST_ASSIST_SUCCESS,
				typeId: assists && assists.length && assists[0].idValueList,
				assists
			})
		)
		.catch((e) => {
			dispatch({ type: POST_ASSIST_ERROR })
			throw e
		})
}

export const PUT_ASSIST_REQUEST = 'PUT_ASSIST_REQUEST'
export const PUT_ASSIST_SUCCESS = 'PUT_ASSIST_SUCCESS'
export const PUT_ASSIST_ERROR = 'PUT_ASSIST_ERROR'

export const updateAssist = (form) => dispatch => {
	dispatch({ type: PUT_ASSIST_REQUEST })

	return api.updateAssist(form)
		.then((assists) => dispatch({
				type: PUT_ASSIST_SUCCESS,
				typeId: assists && assists.length && assists[0].idValueList,
				assists
			})
		)
		.catch((e) => {
			dispatch({ type: PUT_ASSIST_ERROR })
			throw e
		})
}

export const DELETE_ASSIST_REQUEST = 'DELETE_ASSIST_REQUEST'
export const DELETE_ASSIST_SUCCESS = 'DELETE_ASSIST_SUCCESS'
export const DELETE_ASSIST_ERROR = 'DELETE_ASSIST_ERROR'

export const deleteAssist = (form) => dispatch => {
	dispatch({ type: DELETE_ASSIST_REQUEST })

	return api.deleteAssistance(form)
		.then((assists) => dispatch({
				type: DELETE_ASSIST_SUCCESS,
				typeId: assists && assists.length && assists[0].idValueList,
				assists
			})
		)
		.catch((e) => {
			dispatch({ type: DELETE_ASSIST_ERROR })
			throw e
		})
}

