import { withStyles } from '@material-ui/core'
import { getStyles, injectToolbarData, UsersPage } from 'isotope-client'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import styles from '../../styles'

const style = () => getStyles({
	...styles
})

const UserPage = ({ classes }) => {
	return (
		<div
			className={classes.content}
		>
			<UsersPage />
		</div>
	)
}

export default compose(
	withStyles(style),
	injectToolbarData(() => ({
		title: <FormattedMessage id="utilisateur.title" />
	}))
)(UserPage)
