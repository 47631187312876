import MenuItem from '@material-ui/core/MenuItem'
import { FormSelect } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import Button from '../../../components/form/Button'
import Popin from '../../../components/layout/Popin'
import { CREER_ADVICE_FORM } from '../../../config/constants'

const SelectZonePopin = ({ open, submitting, handleSubmit, intl, onClose, districts, centers, districtId, change }) => {

	const closePopup = () => {
		onClose()
	}

	const actions = [
		<Button
			key="save"
			color="primary"
			variant="contained"
			loading={submitting}
			onClick={onClose}
		>
			<FormattedMessage id={'general.btn.enregistrer'} />
		</Button>
	]

	return <Popin
		open={open}
		actions={actions}
		title={intl.formatMessage({ id: 'advice.zone.title' })}
		onClose={closePopup}
		content={
			<form>
				<Field
					name="districtId"
					label={intl.formatMessage({ id: 'advice.creation.district' })}
					component={FormSelect}
				>
					{(districts || []).map(district =>
						<MenuItem
							key={district.id}
							value={district.id}
						>
							{district.name}
						</MenuItem>
					)}
				</Field>
				<Field
					name="centerId"
					label={intl.formatMessage({ id: 'advice.creation.center' })}
					component={FormSelect}
					disabled={!!!districtId}
					onChange={(ev, val) => change('center', centers.find(center => center.id === val).name)}
				>
					{(centers || [])
						.filter(center => center.districtId === districtId)
						.map(center =>
							<MenuItem
								key={center.id}
								value={center.id}
							>
								{center.name}
							</MenuItem>
						)}
				</Field>
			</form>
		}
	/>
}

SelectZonePopin.propTypes = {
	open: PropTypes.bool,
	intl: PropTypes.object,
	onClose: PropTypes.func,
	districts: PropTypes.array,
	centers: PropTypes.array,
	districtId: PropTypes.string
}

const formSelector = formValueSelector(CREER_ADVICE_FORM)

const mapStateToProps = state => ({
	districtId: formSelector(state, 'districtId')
})

const actions = {}

export default compose(
	connect(mapStateToProps, actions),
	injectIntl,
	reduxForm({
		form: CREER_ADVICE_FORM
	})
)(SelectZonePopin)
