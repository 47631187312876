import { reducers as reducersIsotope } from 'isotope-client'
import { assist } from '../modules/assistance/services/assistReducers'
import { brand } from '../modules/brand/services/brandReducers'
import { advice } from '../modules/advice/services/adviceReducers'
import { zones } from '../modules/zone/services/zoneReducers'

export default {
	...reducersIsotope,
	assist,
	brand,
	advice,
	zones
}
