import { default as MUIButton } from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

import PropTypes from 'prop-types'
import React from 'react'

const styles = {
	actionButton: {
		fontSize: 14,
		padding: '8px 25px'
	},
	circularProgress: {
		position: 'absolute'
	},
	loading: {
		visibility: 'hidden'
	}
}

class Button extends React.Component {

	static propTypes = {
		onClick: PropTypes.func,
		color: PropTypes.string,
		disabled: PropTypes.bool,
		loading: PropTypes.bool
	}

	static defaultProps = {
		disabled: false,
		color: 'primary'
	}

	constructor(props) {
		super(props)
		this.state = {
			loading: false
		}
	}

	localOnClick(onClick) {
		if (onClick) {
			const result = onClick()
			if (result && result.then) {
				this.setState({
					loading: true
				})
				this.promise = result
					.then(retour => {
						if (!this._isUnMounted){
							if (retour || typeof retour === 'undefined') {
								this.setState({ loading: false })
							}
							return retour
						}
					})
					.catch((e) => {
						if (!this._isUnMounted){
							this.setState({ loading: false })
							throw e
						}
					})
				return this.promise
			} else {
				return result
			}
		}
	}

	static getDerivedStateFromProps(props, state){
		if (props.loading !== undefined) {
			return {
				loading: props.loading,
			}
		}
		return null
	}

	componentWillUnmount() {
		this._isUnMounted = true
	}

	render() {
		const { onClick, color, disabled, classes, children, loading: loadingPerso , ...otherProps } = this.props
		const { loading } = this.state
		return (
			<MUIButton
				color={color}
				className={classes.actionButton}
				onClick={() => onClick !== undefined && !loading && this.localOnClick(onClick)}
				disabled={disabled || loading}
				{...otherProps}
			>
				<div className={loading ? classes.loading : null}>
					{children}
				</div>
				{loading && <CircularProgress color={color} className={classes.circularProgress} size={20} />}
			</MUIButton>
		)
	}
}

export default withStyles(styles)(Button)
