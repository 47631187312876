import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { destroy, isPristine, reduxForm } from 'redux-form'
import Button from '../../../components/form/Button'
import Popin from '../../../components/layout/Popin'
import { ADVICE_STATE } from '../../../config/constants'
import { updateAdviceStatus } from '../services/adviceActions'

const DELETE_ADVICE_FORM = 'DELETE_ADVICE_FORM'

const DeleteAdvicePopin = ({ open, submitting, handleSubmit, intl, onClose, onError, clearForm, advice, updateAdviceStatus }) => {
	const submitCreation = () => {
		updateAdviceStatus({ id: advice.id, state: ADVICE_STATE.DELETED })
			.then(() => closePopup())
			.catch((e) => onError({
				title: intl.formatMessage({ id: 'error.technique.title' }),
				message: intl.formatMessage({ id: 'error.technique.message' })
			}))
	}

	const closePopup = () => {
		clearForm()
		onClose()
	}

	const actions = [
		<Button
			key="close"
			color="primary"
			onClick={closePopup}
		>
			<FormattedMessage id="general.btn.annuler" />
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			loading={submitting}
			onClick={handleSubmit(submitCreation)}
		>
			<FormattedMessage id='general.btn.delete' />
		</Button>
	]

	return <Popin
		open={open}
		actions={actions}
		title={intl.formatMessage({ id: 'advice.suppression.title' })}
		onClose={closePopup}
		content={
			<FormattedMessage id='advice.suppression.message' />
		}
	/>
}

DeleteAdvicePopin.propTypes = {
	open: PropTypes.bool,
	submitting: PropTypes.bool,
	intl: PropTypes.object,
	handleSubmit: PropTypes.func,
	clearForm: PropTypes.func,
	onClose: PropTypes.func,
	onError: PropTypes.func,
	updateAdviceStatus: PropTypes.func,
	advice: PropTypes.object
}

const mapStateToProps = state => ({
	submitting: isPristine(DELETE_ADVICE_FORM)(state)
})

const actions = {
	clearForm: () => dispatch => dispatch(destroy(DELETE_ADVICE_FORM)),
	updateAdviceStatus: updateAdviceStatus
}

export default compose(
	connect(mapStateToProps, actions),
	injectIntl,
	reduxForm({
		form: DELETE_ADVICE_FORM,
		enableReinitialize: true
	})
)(DeleteAdvicePopin)
