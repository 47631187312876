
const defaultAdminApi = ""

export const fetchCustom = (path, config = {}, apiUrl = defaultAdminApi) => {
	const {
		headers,
		...others
	} = config
	const token = localStorage.getItem('token') || null
	const defaultHeaders = {
		Accept: 'application/json',
		'Content-Type': 'application/json;charset=utf-8'
	}
	if (token) {
		defaultHeaders['Authorization'] = token
	}
	const newConfig = {
		headers: {
			...defaultHeaders,
			...headers
		},
		...others
	}
	return fetch(`${apiUrl}${path}`, newConfig)
		.then(handleResponse)
}

const handleResponse = (response) => {
	// vérification du token
	if (response.status === 401) {
		// si le token n'est pas valide...
		return response.text().then(result => {
			// on déconnecte l'utilisateur
			// et on rejette l'appel
			return Promise.reject(result)
		})
	}
	if (response.status === 204) {
		// no content
		return Promise.resolve()
	}
	if (!response.ok && response.status !== 400) {
		// si la réponse est en erreur sauf erreur de validation, on rejette l'appel
		return Promise.reject(response)
	}
	// dans tous les autres cas, la réponse est traitée en JSON
	return Promise.all([response, response.json()])
		.then(checkErrors)
}

const checkErrors = ([response, json]) => {
	if (response.status === 400) {
		// conversion des erreurs

		return Promise.reject({
			response,
			bodyError: json
		})
	}
	return json
}