import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { destroy, isPristine, reduxForm } from 'redux-form'
import Button from '../../../components/form/Button'
import Popin from '../../../components/layout/Popin'
import { deleteAssist } from '../services/assistActions'

const DELETE_ASSIST_FORM = 'DELETE_ASSIST_FORM'

const DeleteAssistPopin = ({ open, submitting, handleSubmit, intl, onClose, onError, clearForm, assist, deleteAssist }) => {
	const submitCreation = () => {
		deleteAssist(assist)
			.then(() => closePopup())
			.catch((e) => onError({
					title: intl.formatMessage({ id: 'error.technique.title' }),
					message: intl.formatMessage({ id: 'error.technique.message' })
				}
			))
	}

	const closePopup = () => {
		clearForm()
		onClose()
	}

	const actions = [
		<Button
			key="close"
			color="primary"
			onClick={closePopup}
		>
			<FormattedMessage id="general.btn.annuler" />
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			loading={submitting}
			onClick={handleSubmit(submitCreation)}
		>
			<FormattedMessage id='general.btn.delete' />
		</Button>
	]

	return <Popin
		open={open}
		actions={actions}
		title={intl.formatMessage({ id: 'assist.suppression.title' })}
		onClose={closePopup}
		content={
			<FormattedMessage id='assist.suppression.message' />
		}
	/>
}

DeleteAssistPopin.propTypes = {
	open: PropTypes.bool,
	submitting: PropTypes.bool,
	intl: PropTypes.object,
	handleSubmit: PropTypes.func,
	clearForm: PropTypes.func,
	onClose: PropTypes.func,
	onError: PropTypes.func,
	deleteAssist: PropTypes.func,
	assist: PropTypes.object
}

const mapStateToProps = state => ({
	submitting: isPristine(DELETE_ASSIST_FORM)(state)
})

const actions = {
	clearForm: () => dispatch => dispatch(destroy(DELETE_ASSIST_FORM)),
	deleteAssist: deleteAssist
}

export default compose(
	connect(mapStateToProps, actions),
	injectIntl,
	reduxForm({
		form: DELETE_ASSIST_FORM,
		enableReinitialize: true
	})
)(DeleteAssistPopin)
