import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Loader from '../../../components/layout/Loader'
import { fetchBrands } from './brandActions'
import * as selectors from './brandSelectors'

export const loadBrands = (WrappedComponent) => {
	const BrandsInjector = ({ brands, fetchBrands, ...otherProps }) => {
		const [loading, setLoading] = React.useState(false)

		React.useEffect(() => {
			setLoading(true)
			fetchBrands()
				.then(() => {
					setLoading(false)
				})
				.catch(() => {
					setLoading(false)
				})
		}, [])

		return loading ? <Loader /> : <WrappedComponent brands={brands} {...otherProps} />
	}

	BrandsInjector.propTypes = {
		brands: PropTypes.array,
		fetchBrands: PropTypes.func
	}

	const mapStateToProps = state => ({
		brands: selectors.getBrands(state)
	})

	const actions = {
		fetchBrands: fetchBrands
	}

	return compose(
		connect(mapStateToProps, actions)
	)(BrandsInjector)
}
