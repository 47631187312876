import * as actions from './brandActions'

const initialState = {
	brands: []
}

export const brand = (state = initialState, action) => {
	switch (action.type) {
		case actions.GET_BRANDS_SUCCESS:
			return {
				...state,
				brands: action.brands
			}
		case actions.POST_BRAND_SUCCESS:
			return {
				...state,
				brands: [...state.brands, action.brand]
			}
		case actions.PUT_BRAND_SUCCESS:
		case actions.DELETE_BRAND_SUCCESS:
			return {
				...state,
				brands: [...state.brands.filter(brand => brand.id !== action.brand.id), action.brand]
			}
		case actions.GET_BRANDS_ERROR:
			return state
		default:
			return state
	}
}
