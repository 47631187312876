export const BRAND_STATE = {
	CREATED: 1,
	DELETED: 2
}

export const ADVICE_STATE = {
	MODERATE: 1,
	PUBLISHED: 2,
	DELETED: 3
}

export const LANGUAGE = {
	fr: 1,
	en: 2
}

export const MAX_CHAR = {
	brand: {
		name: 50
	},
	advice: {
		model: 50,
		author: {
			lastname: 50,
			firstname: 50
		}
	}
}

/* FORM */
export const BRANDS = [
	{ key: 'alfa romeo', value: 'Alfa Romeo'},
	{ key: 'audi', value: 'Audi'},
	{ key: 'bmw', value: 'BMW'},
	{ key: 'chevrolet', value: 'Chevrolet'},
	{ key: 'citroen', value: 'Citroen'},
	{ key: 'fiat', value: 'Fiat'},
	{ key: 'ford', value: 'Ford'},
	{ key: 'honda', value: 'Honda'},
	{ key: 'infinity', value: 'Infinity'},
	{ key: 'jaguar', value: 'Jaguar'},
	{ key: 'jeep', value: 'Jeep'},
	{ key: 'kia', value: 'Kia'},
	{ key: 'landrover', value: 'Landrover'},
	{ key: 'lexus', value: 'Lexus'},
	{ key: 'mazda', value: 'Mazda'},
	{ key: 'mercedes', value: 'Mercedes'},
	{ key: 'mini', value: 'Mini'},
	{ key: 'mitsubishi', value: 'Mitsubishi'},
	{ key: 'nissan', value: 'Nissan'},
	{ key: 'opel', value: 'Opel'},
	{ key: 'peugeot', value: 'Peugeot'},
	{ key: 'renault', value: 'Renault'},
	{ key: 'seat', value: 'Seat'},
	{ key: 'skoda', value: 'Skoda'},
	{ key: 'suzuki', value: 'Suzuki'},
	{ key: 'toyota', value: 'Toyota'},
	{ key: 'volkswagen', value: 'Volkswagen'},
	{ key: 'volvo', value: 'Volvo'}
]
export const CREER_ADVICE_FORM = 'CREER_ADVICE_FORM'

export const MODELE_ALL = 'Tous modèles'
