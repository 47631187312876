import { Tab, Tabs, withStyles } from '@material-ui/core'
import { getStyles, injectToolbarData, StaticDataTable } from 'isotope-client'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import FloatingButton from '../../components/form/FloatingButton'
import Divider from '../../components/layout/Divider'
import ErrorPopin from '../../components/layout/ErrorPopin'
import { capitalizeFirstLetter } from '../../config/utils'
import styles from '../../styles'
import NewAssistPopin from './components/NewAssistPopin'
import DeleteAssistPopin from './components/DeleteAssistPopin'
import { loadAssist } from './services/assistInjector'

const style = () => getStyles({
	...styles
})

const AssistPage = ({ classes, assistType, assists }) => {
	const [openAddPopup, setOpenAddPopup] = React.useState(false)
	const [openDeletePopup, setOpenDeletePopup] = React.useState(false)
	const [openErrorPopup, setOpenErrorPopup] = React.useState(false)
	const [error, setError] = React.useState(undefined)
	const [selectedType, setSelectedType] = React.useState(assistType && assistType.length && assistType[0].id)
	const [currentAssist, setCurrentAssist] = React.useState(undefined)

	const onClosePopup = (action) => {
		setCurrentAssist(undefined)
		action()
	}

	const sortByName = (a,b) => {
    if(a.code < b.code) { return -1; }
    if(a.code > b.code) { return 1; }
    return 0;
	}

	const actionModifier = (assist) => {
		setCurrentAssist(assist)
		setOpenAddPopup(true)
	}

	const actionSupprimer = (assist) => {
		setCurrentAssist(assist)
		setOpenDeletePopup(true)
	}

	return (
		<div
			className={classes.content}
		>
			<Tabs
				value={selectedType}
				indicatorColor='primary'
				textColor='primary'
				onChange={(event, value) => setSelectedType(value)}
				variant="fullWidth"
				className={classes.tab}
			>
				{assistType.map(type => (
						<Tab value={type.id} label={type.description} key={type.id} />
					)
				)}
			</Tabs>
			<Divider />
			<div className={classes.tableContent}>
				<StaticDataTable
					className={classes.dataTable}
					key={`LIST_${selectedType}`}
					data={(assists[selectedType] || []).filter(assist => !assist.disabled).sort(sortByName)}
					defaultPageSize={50}
					headers={[
						{
							key: 'code',
							name: <div className={classes.key}><FormattedMessage id='assist.keys.district' /></div>,
							render: row => row.code,
							sortable: false
						},
						{
							key: 'label',
							name: assistType &&
							assistType.length &&
							<div className={classes.key}>
								{capitalizeFirstLetter(assistType[selectedType].description)}
							</div>,
							render: row => row.label,
							sortable: false
						}
					]}
					makeActions={row => [
						{ label: <FormattedMessage id='general.btn.modifier' />, action: () => actionModifier(row) },
						{ label: <FormattedMessage id='general.btn.delete' />, action: () => actionSupprimer(row) }
					]}
					showActions
				/>
			</div>
			<FloatingButton onClick={() => setOpenAddPopup(true)} />
			<NewAssistPopin
				open={openAddPopup}
				onClose={() => onClosePopup(() => setOpenAddPopup(false))}
				onError={(e) =>
					onClosePopup(() => {
						setError(e)
						setOpenErrorPopup(true)
						setOpenAddPopup(false)
					})
				}
				assistType={assistType[selectedType]}
				assist={currentAssist}
				assists={(assists[selectedType] || [])}
			/>
			<DeleteAssistPopin
				open={openDeletePopup}
				onClose={() => onClosePopup(() => setOpenDeletePopup(false))}
				onError={(e) =>
					onClosePopup(() => {
						setError(e)
						setOpenErrorPopup(true)
						setOpenDeletePopup(false)
					})
				}
				assist={currentAssist}
			/>
			<ErrorPopin
				open={openErrorPopup}
				onClose={() => setOpenErrorPopup(false)}
				errorTitle={error && error.title}
				errorMessage={error && error.message}
			/>

		</div>
	)
}

export default compose(
	loadAssist,
	withStyles(style),
	injectToolbarData(() => ({
		title: <FormattedMessage id="assist.title" />
	}))
)(AssistPage)
