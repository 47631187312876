import * as api from './adviceApi'

export const GET_ADVICES_BY_BRAND_REQUEST = 'GET_ADVICES_BY_BRAND_REQUEST'
export const GET_ADVICES_BY_BRAND_SUCCESS = 'GET_ADVICES_BY_BRAND_SUCCESS'
export const GET_ADVICES_BY_BRAND_ERROR = 'GET_ADVICES_BY_BRAND_ERROR'

export const fetchAdvices = (brandId) => dispatch => {
	dispatch({ type: GET_ADVICES_BY_BRAND_REQUEST })

	return api.fetchAdvices(brandId)
		.then((advices) => dispatch({ type: GET_ADVICES_BY_BRAND_SUCCESS, brandId, advices }))
		.catch((e) => {
			dispatch({ type: GET_ADVICES_BY_BRAND_ERROR })
			throw e
		})
}

export const POST_ADVICE_REQUEST = 'POST_ADVICE_REQUEST'
export const POST_ADVICE_SUCCESS = 'POST_ADVICE_SUCCESS'
export const POST_ADVICE_ERROR = 'POST_ADVICE_ERROR'

export const newAdvice = (form) => dispatch => {
	dispatch({ type: POST_ADVICE_REQUEST })

	return api.newAdvice(form)
		.then((advice) => {
				return dispatch({
					type: POST_ADVICE_SUCCESS,
					advice: advice
				})
			}
		)
		.catch((e) => {
			dispatch({ type: POST_ADVICE_ERROR })
			throw e
		})
}

export const PUT_ADVICE_REQUEST = 'PUT_ADVICE_REQUEST'
export const PUT_ADVICE_SUCCESS = 'PUT_ADVICE_SUCCESS'
export const PUT_ADVICE_ERROR = 'PUT_ADVICE_ERROR'

export const updateAdvice = (form, idDeleted, base64List) => dispatch => {
	dispatch({ type: PUT_ADVICE_REQUEST })

	return api.updateAdvice(form, idDeleted, base64List)
		.then((advice) => dispatch({
				type: PUT_ADVICE_SUCCESS,
				advice: advice
			})
		)
		.catch((e) => {
			dispatch({ type: PUT_ADVICE_ERROR })
			throw e
		})
}


export const GET_TICKET_REQUEST = 'GET_TICKET_REQUEST'
export const GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS'
export const GET_TICKET_ERROR = 'GET_TICKET_ERROR'

export const getTicket = () => dispatch => {
	dispatch({ type: GET_TICKET_REQUEST })

	return api.getTicket()
		.then((ticket) => dispatch({
				type: GET_TICKET_SUCCESS,
				ticket: ticket
			})
		)
		.catch((e) => {
			dispatch({ type: GET_TICKET_ERROR })
			throw e
		})
}


export const GET_DOCUMENT_REQUEST = 'GET_DOCUMENT_REQUEST'
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS'
export const GET_DOCUMENT_ERROR = 'GET_DOCUMENT_ERROR'

export const getDocument = (idAdvice,filename,ticket) => dispatch => {
	dispatch({ type: GET_DOCUMENT_REQUEST })

	return api.getDocument(idAdvice,filename,ticket)
		.then((document) => dispatch({
				type: GET_DOCUMENT_SUCCESS,
				document: document
			})
		)
		.catch((e) => {
			dispatch({ type: GET_DOCUMENT_ERROR })
			throw e
		})
}

export const PUT_ADVICE_STATUS_REQUEST = 'PUT_ADVICE_STATUS_REQUEST'
export const PUT_ADVICE_STATUS_SUCCESS = 'PUT_ADVICE_STATUS_SUCCESS'
export const PUT_ADVICE_STATUS_ERROR = 'PUT_ADVICE_STATUS_ERROR'

export const updateAdviceStatus = (form) => dispatch => {
	dispatch({ type: PUT_ADVICE_STATUS_REQUEST })

	return api.updateAdviceStatus(form)
		.then((advice) => dispatch({
				type: PUT_ADVICE_STATUS_SUCCESS,
				advice: advice
			})
		)
		.catch((e) => {
			dispatch({ type: PUT_ADVICE_STATUS_ERROR })
			throw e
		})
}

