import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'

const style = () => getStyles({
	link: {
		cursor: 'pointer',
		fontWeight: 'bold'
	}
})

/**
 * Link pour naviguer dans les différentes pages
 * Ce composant reprend les paramètres utilisés par Link de react-router (to, query, state)
 */
const Link = ({ to, state, query, onClick, children, goTo, classes }) => (
	<span className={classes.link} onClick={() => {
		onClick()
		goTo(to, query, state)
	}}>
	{children}
	</span>
)

const actions = {
	goTo: (pathname, search, state) => dispatch => dispatch(push({
		pathname,
		search,
		state
	}))
}

Link.defaultProps = {
	to: '',
	query: '',
	state: {},
	onClick: () => {}
}

Link.propTypes = {
	to: PropTypes.string.isRequired,
	query: PropTypes.string,
	state: PropTypes.object,
	onClick: PropTypes.func,
	classes: PropTypes.object,
	goTo: PropTypes.func.isRequired
}

export default compose(
	connect(null, actions),
	withStyles(style)
)(Link)
