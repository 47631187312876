import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Loader from '../../../components/layout/Loader'
import { fetchAssistsList, fetchAssistsType } from './assistActions'
import * as selectors from './assistSelectors'

export const loadAssist = (WrappedComponent) => {
	const AssistInjector = ({ assistType, fetchAssistsType, assists, fetchAssistsList, ...otherProps }) => {
		const [loading, setLoading] = React.useState(false)

		React.useEffect(() => {
			setLoading(true)
			fetchAssistsType()
				.then(result => {
					const promises = result.reduce((acc, current) => fetchAssistsList(current.id), [])
					return Promise.all(promises)
				})
				.then(() => {
					setLoading(false)
				})
				.catch(() => {
					setLoading(false)
				})
		}, [])

		return loading ? <Loader /> : <WrappedComponent assistType={assistType} assists={assists} {...otherProps} />
	}

	AssistInjector.propTypes = {
		assistType: PropTypes.array,
		assists: PropTypes.object,
		fetchAssistsType: PropTypes.func,
		fetchAssistsList: PropTypes.func
	}

	const mapStateToProps = state => ({
		assistType: selectors.getAssistType(state),
		assists: selectors.getAssists(state)
	})

	const actions = {
		fetchAssistsType: fetchAssistsType,
		fetchAssistsList: fetchAssistsList
	}

	return compose(
		connect(mapStateToProps, actions)
	)(AssistInjector)
}
