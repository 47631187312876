import { Typography, withStyles } from '@material-ui/core'
import CommentIcon from '@material-ui/icons/Comment'
import { getStyles, injectToolbarData, StaticDataTable } from 'isotope-client'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'redux'
import FloatingButton from '../../components/form/FloatingButton'
import ErrorPopin from '../../components/layout/ErrorPopin'
import Link from '../../components/Link'
import { BRAND_STATE } from '../../config/constants'
import styles from '../../styles'
import DeleteBrandPopin from './components/DeleteBrandPopin'
import NewBrandPopin from './components/NewBrandPopin'
import { loadBrands } from './services/brandInjector'

const style = () => getStyles({
	...styles
})

const BrandPage = ({ classes, intl, brands }) => {
	const [openAddPopup, setOpenAddPopup] = React.useState(false)
	const [openDeletePopup, setOpenDeletePopup] = React.useState(false)
	const [openErrorPopup, setOpenErrorPopup] = React.useState(false)
	const [error, setError] = React.useState(undefined)
	const [currentBrand, setCurrentBrand] = React.useState(undefined)

	const onClosePopup = (action) => {
		setCurrentBrand(undefined)
		action()
	}

	const actionModifier = (brand) => {
		setCurrentBrand(brand)
		setOpenAddPopup(true)
	}

	const actionSupprimer = (brand) => {
		setCurrentBrand(brand)
		setOpenDeletePopup(true)
	}

	const numberOfTipsWainting = () => {
		const nbTips = brands ? brands.reduce((acc, val) => acc + val.advicesToModerate, 0) : 0
		return nbTips
	}

	const numberOfTips = () => {
		const nbTips = brands ? brands.reduce((acc, val) => acc + val.advicesToModerate + val.publishedAdvices, 0) : 0
		return nbTips
	}

	return (
		<div
			className={classes.content}
		>
			<Typography
				variant="h5">{intl.formatMessage({id:'brand.sumTips'}, { number: numberOfTips() })}</Typography>
			<Typography
				variant="h5">{intl.formatMessage({ id: numberOfTipsWainting() === 0 ? 'brand.subTitleTipNone' : (numberOfTipsWainting() > 1 ? 'brand.subtitleTips' : 'brand.subtitleTip') }, { number: numberOfTipsWainting() })}</Typography>
			<div className={classes.tableContent}>
				<StaticDataTable
					data={(brands || []).sort((a, b) => a.name.localeCompare(b.name)).filter(brand => brand.state === BRAND_STATE.CREATED)}
					defaultPageSize={50}
					headers={[
						{
							key: 'name',
							name: <div className={classes.key}><FormattedMessage id='brand.keys.brand' /></div>,
							render: row => row.name,
							type: 'string',
							sortable: true
						},
						{
							key: 'publishedAdvices',
							name: <div className={classes.key}><FormattedMessage id='brand.keys.advice' /></div>,
							render: row => row.publishedAdvices ? row.publishedAdvices : 0,
							sortable: true
						},
						{
							key: 'advicesToModerate',
							name: <div className={classes.key}><FormattedMessage id='brand.keys.moderate' /></div>,
							render: row =>
								(
									<div className={classes.pastille}>
									<span className={classes.pastilleText}>
										{row.advicesToModerate ? row.advicesToModerate : 0}
									</span>
									</div>
								),
							sortable: true
						},
						{
							key: 'selection',
							name: undefined,
							render: row => <Link to={`/advices/${row.id}/${row.name}`}><CommentIcon className={classes.icon} /></Link>,
							sortable: true
						}
					]}
					makeActions={row => [
						{ label: <FormattedMessage id='general.btn.modifier' />, action: () => actionModifier(row) },
						{ label: <FormattedMessage id='general.btn.delete' />, action: () => actionSupprimer(row) }
					]}
					showActions
				/>
			</div>
			<FloatingButton onClick={() => setOpenAddPopup(true)} />
			<NewBrandPopin
				open={openAddPopup}
				onClose={() => onClosePopup(() => setOpenAddPopup(false))}
				onError={(e) =>
					onClosePopup(() => {
						setError(e)
						setOpenErrorPopup(true)
						setOpenAddPopup(false)
					})
				}
				brand={currentBrand}
				brands={brands}
			/>
			<DeleteBrandPopin
				open={openDeletePopup}
				onClose={() => onClosePopup(() => setOpenDeletePopup(false))}
				onError={(e) =>
					onClosePopup(() => {
						setError(e)
						setOpenDeletePopup(false)
						setOpenErrorPopup(true)
					})
				}
				brand={currentBrand}
			/>
			<ErrorPopin
				open={openErrorPopup}
				onClose={() => setOpenErrorPopup(false)}
				errorTitle={error && error.title}
				errorMessage={error && error.message}
			/>
		</div>
	)
}

export default compose(
	loadBrands,
	injectIntl,
	withStyles(style),
	injectToolbarData(() => ({
		title: <FormattedMessage id="brand.title" />
	}))
)(BrandPage)
