import * as actions from './assistActions'

const initialState = {
	type: [],
	list: {}
}

export const assist = (state = initialState, action) => {
	switch (action.type) {
		case actions.GET_ASSIST_TYPE_SUCCESS:
			return {
				...state,
				type: action.assistType
			}
		case actions.GET_ASSIST_SUCCESS:
		case actions.POST_ASSIST_SUCCESS:
		case actions.PUT_ASSIST_SUCCESS:
		case actions.DELETE_ASSIST_SUCCESS:
			return {
				...state,
				list: {
					...state.list,
					[action.typeId]: action.assists
				}
			}
		case actions.GET_ASSIST_ERROR:
			return state
		default:
			return state
	}
}
