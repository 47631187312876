import withStyles from '@material-ui/core/styles/withStyles'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'

const styles = () => getStyles({
	fab: {
		position: 'fixed',
		right: 20,
		bottom: 20
	}
})

const FloatingButton = ({ classes, color, label, icon, onClick }) => (
	<Fab
		className={classes.fab}
		color={color}
		label={label}
		onClick={onClick}
	>
		{icon}
	</Fab>
)

FloatingButton.defaultProps = {
	color: 'primary',
	label: 'Add',
	icon: <AddIcon />,
	onClick: () => {}
}


FloatingButton.propTypes = {
	classes: PropTypes.object,
	onClick: PropTypes.func.isRequired,
	icon: PropTypes.node.isRequired,
	color: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired
}

export default compose(
	withStyles(styles)
)(FloatingButton)
