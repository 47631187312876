import { FormInput, FormSelect } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { destroy, Field, isPristine, reduxForm } from 'redux-form'
import Button from '../../../components/form/Button'
import Popin from '../../../components/layout/Popin'
import { BRAND_STATE, BRANDS, MAX_CHAR } from '../../../config/constants'
import { newBrand, updateBrand } from '../services/brandActions'
import MenuItem from '@material-ui/core/MenuItem'

const NEW_BRAND_FORM = 'NEW_BRAND_FORM'

const validate = (values) => {
	const errors = {}

	if (!values.name) {
		errors.name = { id: 'general.erreur.obligatoire' }
	}

	return errors
}

const NewMarquePopin = ({ open, submitting, handleSubmit, intl, onClose, onError, clearForm, newBrand, updateBrand, brand, brands }) => {
	const submitCreation = (values) => {
		// on check si on est en création (pas de brand en props)
		if (!brand &&
			brands.find(brand => brand.name.toLowerCase() === values.name.toLowerCase() && brand.state === BRAND_STATE.CREATED)) {
			return onError({
				title: intl.formatMessage({ id: 'error.general.title' }),
				message: intl.formatMessage({ id: 'brand.creation.error' })
			})
		}

		// On check si on est en train de créer une brand qui existe deja dans notre base mais à l'état deleted, on la modifie pour la passer à l'état créé
		const existingMarque = brands.find(brand => brand.state === BRAND_STATE.DELETED && brand.name.toLowerCase() === values.name.toLowerCase())

		const brandValue = {
			...values,
			id: (brand && brand.id) || (existingMarque && existingMarque.id) || undefined, // On remet l'id de la brand qu'on a déjà dans le store si il existe
			state: BRAND_STATE.CREATED // on set le state à créer
		}

		if (brand || existingMarque) {
			// Modification
			updateBrand(brandValue)
				.then(() => closePopup())
				.catch((e) => onError({
						title: intl.formatMessage({ id: 'error.technique.title' }),
						message: intl.formatMessage({ id: 'error.technique.message' })
					}
				))
		} else {
			// Creation
			newBrand(brandValue)
				.then(() => closePopup())
				.catch((e) => onError({
						title: intl.formatMessage({ id: 'error.technique.title' }),
						message: intl.formatMessage({ id: 'error.technique.message' })
					}
				))
		}
	}

	const closePopup = () => {
		clearForm()
		onClose()
	}

	const actions = [
		<Button
			key="close"
			color="primary"
			onClick={closePopup}
		>
			<FormattedMessage id="general.btn.annuler" />
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			loading={submitting}
			onClick={handleSubmit(submitCreation)}
		>
			{brand ? <FormattedMessage id='general.btn.modifier' /> : <FormattedMessage id='general.btn.creer' />}
		</Button>
	]

	return <Popin
		open={open}
		actions={actions}
		title={intl.formatMessage({ id: brand ? 'brand.modification.title' : 'brand.creation.title' })}
		onClose={closePopup}
		content={
			<form>
				<Field
					name="name"
					label={intl.formatMessage({ id: 'brand.creation.name' })}
					component={FormInput}
					inputProps={{ maxLength: MAX_CHAR.brand.name }}
				/>
				<Field
					name="logo"
					label={intl.formatMessage({ id: 'brand.creation.logo' })}
					component={FormSelect}
				>
					{(BRANDS)
						.sort(((a, b) => a && b ? a.value.localeCompare(b.value) : false))
						.map(brand =>
							<MenuItem
								key={brand.key}
								value={brand.key}
							>
								{brand.value}
							</MenuItem>
						)}
				</Field>
			</form>
		}
	/>
}

NewMarquePopin.propTypes = {
	open: PropTypes.bool,
	submitting: PropTypes.bool,
	intl: PropTypes.object,
	handleSubmit: PropTypes.func,
	clearForm: PropTypes.func,
	onClose: PropTypes.func,
	onError: PropTypes.func,
	newBrand: PropTypes.func,
	updateBrand: PropTypes.func,
	brand: PropTypes.object,
	brands: PropTypes.array

}

const mapStateToProps = (state, { brand }) => ({
	initialValues: {
		...brand
	},
	submitting: isPristine(NEW_BRAND_FORM)(state)
})

const actions = {
	clearForm: () => dispatch => dispatch(destroy(NEW_BRAND_FORM)),
	newBrand: newBrand,
	updateBrand: updateBrand
}

export default compose(
	connect(mapStateToProps, actions),
	injectIntl,
	reduxForm({
		form: NEW_BRAND_FORM,
		validate,
		enableReinitialize: true
	})
)(NewMarquePopin)
