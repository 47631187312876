import * as actions from './zoneActions'

const initialState = {
	centers: [],
	districts: []
}

export const zones = (state = initialState, action) => {
	switch (action.type) {
		case actions.GET_ZONES_SUCCESS:
			return {
				centers: action.zones.listCenter,
				districts: action.zones.listDistrict,
			}
		case actions.GET_ZONES_ERROR:
			return state
		default:
			return state
	}
}
