import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import Cropper from 'react-cropper'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'redux'
import Button from '../../../components/form/Button'
import Popin from '../../../components/layout/Popin'
import "cropperjs/dist/cropper.css";



const ModifyImagePopin = ({ open, submitting, url,  onClose, onSuccess }) => {

	const cropRef = useRef()
	const [croppedImg, setCroppedImg] = React.useState(undefined)

	const closePopup = () => {
		onClose()
	}

	const closeSuccess = () => {
		onSuccess(croppedImg)
		onClose()
	}

	const handleCropChange = () => {
		const croppedImgData = cropRef.current.cropper
			.getCroppedCanvas()
			.toDataURL()
			setCroppedImg(croppedImgData)
	}

	const handleRotate = () => {
		cropRef.current.cropper.rotate(90)
		handleCropChange()
	}

	const actions = [
		<Button
			key="close"
			color="primary"
			onClick={closePopup}
		>
			<FormattedMessage id="general.btn.annuler" />
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			loading={submitting}
			onClick={closeSuccess}
		>
			<FormattedMessage id='general.btn.enregistrer' />
		</Button>
	]

	return <Popin
		open={open}
		actions={actions}
		title={'Modification'}
		onClose={closePopup}
		content={
			<div>
				<Cropper
					style={{ maxWidth: '400px', height: '400px' }}
					ref={cropRef}
					movable={false}
					zoomable={false}
					src={url}
					cropend={() => handleCropChange()}
				/>
				<button style={{marginTop:20}} onClick={() => handleRotate()}>Faire pivoter la photo</button>
			</div>
		}
	/>
}

ModifyImagePopin.propTypes = {
	open: PropTypes.bool,
	submitting: PropTypes.bool,
	intl: PropTypes.object,
	handleSubmit: PropTypes.func,
	clearForm: PropTypes.func,
	onClose: PropTypes.func,
	onError: PropTypes.func,
	updateAdviceStatus: PropTypes.func,
	advice: PropTypes.object
}


export default compose(
	injectIntl
)(ModifyImagePopin)
