import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { destroy, isPristine, reduxForm } from 'redux-form'
import Button from '../../../components/form/Button'
import Popin from '../../../components/layout/Popin'
import { ADVICE_STATE } from '../../../config/constants'
import { updateAdviceStatus } from '../services/adviceActions'

const PUBLISH_ADVICE_FORM = 'PUBLISH_ADVICE_FORM'

const PublishAdvicePopin = ({ open, submitting, handleSubmit, intl, onClose, onError, clearForm, advice, putStatusConseil }) => {
	const submitCreation = () => {
		putStatusConseil({ id: advice.id, state: advice.state === ADVICE_STATE.MODERATE ? ADVICE_STATE.PUBLISHED : ADVICE_STATE.MODERATE })
			.then(() => closePopup())
			.catch((e) => onError({
				title: intl.formatMessage({ id: 'error.technique.title' }),
				message: intl.formatMessage({ id: 'error.technique.message' })
			}))
	}

	const closePopup = () => {
		clearForm()
		onClose()
	}

	const actions = [
		<Button
			key="close"
			color="primary"
			onClick={closePopup}
		>
			<FormattedMessage id="general.btn.annuler" />
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			loading={submitting}
			onClick={handleSubmit(submitCreation)}
		>
			<FormattedMessage id={advice && advice.state === ADVICE_STATE.MODERATE ? 'advice.actions.publier' : 'advice.actions.depublier'} />
		</Button>
	]

	return <Popin
		open={open}
		actions={actions}
		title={intl.formatMessage({ id: advice && advice.state === ADVICE_STATE.MODERATE ? 'advice.publier.title' : 'advice.depublier.title' })}
		onClose={closePopup}
		content={
			<FormattedMessage id={advice && advice.state === ADVICE_STATE.MODERATE ? 'advice.publier.message' : 'advice.depublier.message'} />
		}
	/>
}

PublishAdvicePopin.propTypes = {
	open: PropTypes.bool,
	submitting: PropTypes.bool,
	intl: PropTypes.object,
	handleSubmit: PropTypes.func,
	clearForm: PropTypes.func,
	onClose: PropTypes.func,
	onError: PropTypes.func,
	putStatusConseil: PropTypes.func,
	advice: PropTypes.object
}

const mapStateToProps = state => ({
	submitting: isPristine(PUBLISH_ADVICE_FORM)(state)
})

const actions = {
	clearForm: () => dispatch => dispatch(destroy(PUBLISH_ADVICE_FORM)),
	putStatusConseil: updateAdviceStatus
}

export default compose(
	connect(mapStateToProps, actions),
	injectIntl,
	reduxForm({
		form: PUBLISH_ADVICE_FORM,
		enableReinitialize: true
	})
)(PublishAdvicePopin)
