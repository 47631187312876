import { fetchFactory } from 'isotope-client'
import { LANGUAGE } from '../../../config/constants'

export const fetchAssistType = () => fetchFactory(`/value-list`)

export const fetchAssistsList = (id) => fetchFactory(`/value-list/${id}/entries`)

export const newAssist = (assist) => fetchFactory('/value-list-entries', {
	method: 'POST',
	body: JSON.stringify({
		code: assist.code,
		beans: [
			{
				...assist,
				priority: 0,
				disabled: false,
				idLanguage: LANGUAGE.fr // par défaut, fr
			}
		]
	})
})

export const updateAssist = (assist) => fetchFactory('/value-list-entries', {
	method: 'PUT',
	body: JSON.stringify({
		code: assist.code,
		beans: [
			{
				...assist
			}
		]
	})
})

export const deleteAssistance = (assist) => fetchFactory('/value-list-entries', {
	method: 'PUT',
	body: JSON.stringify({
		code: assist.code,
		beans: [
			{
				...assist,
				disabled: true
			}
		]
	})
})
