import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

import { getStyles } from 'isotope-client'
import React from 'react'

const styles = () => getStyles({
	container: {
		minHeight: '100vh'
	},
	absolute: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0
	},
	circularProgress: {
		margin: 'auto'
	}
})

const Loader = ({ classes, minHeight = true, absolute = false, style = '' }) => (

	<Grid container className={classNames(style, { [classes.absolute]: absolute, [classes.container]: !absolute && minHeight })}>
		<Grid item className={classes.circularProgress}>
			<CircularProgress />
		</Grid>
	</Grid>
)

export default withStyles(styles)(Loader)
